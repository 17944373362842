.schedule-col {
    width: calc(100% / 8);
}

.schedule-day {
    width: calc(100% / 7);
}

.schedule-class {
    border-radius: 5px;
    text-align: center;
    padding: 12px;
}

.schedule-class:hover {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-classes-container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 100%;
    max-width: 300%;
    padding: 12px;
    float: left;
}

.schedule-mobile-container {
    overflow-x: scroll;
}

.schedule-mobile-wrapper {
    width: 300%;
}

#schedule-mobile-wrapper {
    display: none;
}

@media(max-width: 767px) {
    .schedule-col {
        width: calc(100% / 7);
    }

    .schedule-day {
        width: 100%;
    }

    .schedule-container {
        width: 100%;
    }

    #schedule-mobile-wrapper {
        display: inherit;
    }
}