@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap');

@font-face {
    font-family: "Francois One";
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/FrancoisOne-Regular.woff") format("woff")
}

@font-face {
    font-family: "Futura";
    font-weight: 400;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-light.woff") format("woff")
}

@font-face {
    font-family: "Futura Condensed";
    font-weight: 400;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-light.woff") format("woff")
}

@font-face {
    font-family: "Futura";
    font-weight: 800;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura.woff") format("woff")
}

@font-face {
    font-family: "Futura Condensed Extra Bold";
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-condensed-extra-bold.woff") format("woff")
}


body, span, li, div, p, .font-body, .accordion-button, .nav-link, .font-poppins {
    font-family: "Poppins", sans-serif !important;
}

.h2.font-poppins, .h3.font-poppins {
    font-family: "Poppins", sans-serif !important;
    text-transform: none;
    font-weight: 300 !important;
}

p {
    font-size: 1.2rem;
}

p.medium {
    font-size: 1rem;
}

.text-sentence {
    font-style: normal;
    text-transform: initial;
}

.font-open-sans {
    font-family: "Open Sans", sans-serif !important;
}

.font-style-italic {
    font-style: italic !important;
}

.futura {
    font-family: "Futura", sans-serif !important;
}

.futura-bold {
    font-family: "Futura Condensed Extra Bold", sans-serif !important;
}

.francois {
    font-family: "Francois One", sans-serif !important;
}

h1, h2, .font-header {
    font-weight: bold;
    line-height: 1.8em;
    font-family: "Futura Condensed Extra Bold", sans-serif !important;
}

h3, h4, h5, h6, .h3, .h4, .h5, .h6, .font-header-secondary {
    text-transform: uppercase;
    font-family: "Futura Condensed Extra Bold", sans-serif !important;
}

.unstyled {
    font-style: none !important;
}

h1.display-1 {
    font-size: calc(4rem + 4.5vw);
}