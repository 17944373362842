#video {
    margin-top: 60px;
}

.home-video {
    padding-right: 0px;
    padding-left: 0px;
    z-index: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.plan-card {
    height: 600px;
}

.plan-meta {
    height: 200px;
}

.plan-card .btn-pink {
    position: absolute;
    top: 0px;
    right: 10px;
}

.plan-image {
    top: 0px;
    left: 0px;
    height: 450px;
    object-fit: cover;
    position: absolute;
    border-radius: 15px 15px 0px 0px;
}

.class-type-card {
    height: 300px;
}

.class-type-card-lg {
    height: 650px;
}

.class-type-videos {
    position: absolute;
    right: 10px;
    top: 10px;
}

.class-type-meta {
    height: 75px;
}

.class-type-row * {
    transition: all 0.25s;
}

.class-type-wrapper, .coaches-wrapper {
    max-width: 100vw;
    overflow: hidden;
}

.estrenos-wrapper {
    height: 250px;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.outline-coach-image {
    border-radius: 60px;
    position: absolute;
    height: 335px;
    width: 250px;
    z-index: 0;
    left: 35px;
    top: 30px;
}

.outline-estreno-image {
    border-radius: 20px;
    position: absolute;
    height: 300px;
    width: 200px;
    z-index: 0;
    left: -5px;
    top: 0px;
}

.bg-coach-image {
    border-radius: 60px;
    position: absolute;
    height: 325px;
    width: 250px;
    z-index: 0;
    left: 70px;
    top: 50px;
}

.bg-estreno-image {
    border-radius: 30px;
    position: absolute;
    height: 300px;
    width: 200px;
    right: 25px;
    top: 25px;
}

.estreno-image {
    height: 100%;
    object-fit: cover;
}

.estreno-card {    
    width: 300px;
    display: flex;
    flex-direction: column;
}

.estreno-card .date-overlay {
    position: absolute;
    top: 50px;
    left: 15px;
    width: 90%;
}

.estreno-card img {
    height: 150px; 
    object-fit: cover;
}

.video-estreno-image {
    border-radius: 20px;
    height: 200px;
    width: 400px;
}

.home-coach-image {
    z-index: 1;
    margin: auto;
    display: block;    
    object-fit: cover;
}

.estrenos-row {
    transition: all 0.25s;
}

.coaches-row {
    max-height: auto;
}

.class-type-row, .coaches-row {
    transition: all 0.25s;
    overflow-x: visible;
    overflow-y: hidden;
    max-width: 300%;
    max-height: 700px;
    width: 300%;
}

.class-type-column, .coach-column {
    height: 780px;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: all 0.5s;
    width: 25vw;
}

.class-type-meta, .plan-meta, .program-meta {
    border-radius: 0px 0px 15px 15px;
    justify-content: center;
    flex-direction: column;
    transition: all 0.25s;
    align-items: center;
    position: absolute;    
    display: flex;
    width: 100%;
    bottom: 0px;
    left: 0px;
}

.panel-card {
    height: 500px !important;
    transition: all 0.25s;
}

.program-card {
    height: 500px;
    transition: all 0.25s;
}

.nutrition-tip-image {
    border-radius: 0px 0px 25px 25px;
    position: absolute;
    bottom: 0px;
}

.program-meta { 
    height: 100px;
}

.program-meta .program-title {
    margin-bottom: 0px;
    font-size: 22px;
}

.program-description {
    display: none;
}

.program-card:hover .program-meta {
    height: 200px;
}

.program-card:hover .program-description {
    display: block;
}
.program-card:hover .program-title {
    margin-bottom: 12px;
}

#single-estreno { 
    height: 380px;
}

#video .tagline {
    font-size: 34px;
}

#about img {
    max-height: 350px;
    object-fit: cover;
    
}

#about #center-image {
    height: 600px;
}

#video .card {
    background-color: rgba(10,10,10,0.25);
    border-radius: 15px;
    color: white;
}

#video,
.home-video {
    height: 70vh;
}


/* To Do: Centrar en todas las pantallas */
#video #buttons {
    position: absolute;
    width: 75%;
    left: 0;
    bottom: -32px;
    margin-left: 12.5%;
}

#quote #buttons {
    max-width: 1000px;
    margin: auto;
}

@media (max-width: 767px) {
    .display-1 {
        font-size: 2.4rem !important;
    }
    .tagline { 
        font-size: 1.8rem !important;
    }
    #video #buttons {
        position: relative;
        margin-left: 0px;
        margin-top: 0px;        
        width: 100%;         
    }
    .coach-column {            
        overflow-y: hidden;
        overflow-x: hidden;
        width: 90vw;
    }
    .class-type-row {
        height: 475px;
    }
    .class-type-column {
        width: 75vw;
        height: 500px;
    }
    .class-type-card-lg {
        height: 400px;
    }
    .carousel-control-prev.wide {
        margin-left: -50px;
    }
    .carousel-control-next.wide {
        margin-right: -50px;
    }
    .carousel-control-prev.dark {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    .carousel-control-next.dark {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    .plan-image {
        height: 300px;        
    }
    .plan-card {
        height: 450px;
    }
    .plan-meta {
        padding: 16px;
    }
    .navbar-cta {
        margin-top: 0px;
    }
    nav .collapse.show {
        margin-bottom: 16px;
    }
    .estreno-image {
        height: auto;
        margin-top: 24px;
        object-fit: cover;
    }
    .program-video-card {
        min-height: auto;
    }
    #single-estreno .h-100 {
        height: auto !important;
    }
    #single-estreno {
        height: 500px;
    }
}