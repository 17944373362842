

#post-input {
    position: absolute;
    bottom: -50px;
    width: 100%;
}

.post-actions {
    position: absolute;
    width: 150px;
    right: 75px;
    bottom: 15px;
    z-index: 10;
}

#posts {
    height: 100vh;
}

#posts-content {
    height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.emoji-picker-wrapper {
    position: relative;
}

.emoji-picker-wrapper button {
    position: absolute;
    top: -575px;
    right: -5px;
}

aside.EmojiPickerReact.epr-main {
    position: absolute !important;
    top: -535px;
    right: -5px;
}

#greeting {
    height: 80vh;
}

@media (max-width: 767px) {
    #posts {
        height: calc(100vh - 70px);
    }
    #posts-content {
        height: 75vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    #post-input {
        bottom: -100px;
    }
}