@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;700&display=swap);
.h1 .normal {
  font-weight: 400;
}

td, th {
  min-width: 100px;
  padding: 8px;
  width: auto;
}

table.leaderboard td {
  min-width: 175px;
}

thead {
  font-family: "Poppins", sans-serif;
}

.table > :not(caption) > * > * {
  padding: 10px 12px;
}

.table > :not(:last-child) > :last-child > * {
  border-bottom: inherit;
}

#field-wrapper {
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.mw-1000 {
  max-width: 1000px;
}

.hover-grow {
  transition: all 0.25s;
}

.hover-grow:hover {
  transform: scale(1.05);
}

.btn {
  border-radius: 10px;
}

.btn-focus-disabled:focus {
  box-shadow: none !important;
}

.w-max-content {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.btn-primary,
.btn-primary,
.btn-primary-secondary,
.btn-light {
  font-family: "Poppins", sans-serif;
}

.btn-container {
  width: 103.2% !important;
}

.bg-accent {
  color: white !important;
}

.btn-primary {
  transition: all 0.25s;
  text-transform: uppercase;
  font-weight: 800;
}

.btn-primary:hover {
  transform: scale(1.05);
}

.bg-black {
  background-color: #000;
}

#landing .form-control {
  margin-top: 12px;
  background-color: #2b2b2b;
  color: #fff !important;
  border: 1px solid rgba(0, 0, 0);
}

#landing .form-control:focus {
  margin-top: 12px;
  background-color: #2b2b2b;
  color: #fff !important;
}

.vibe-card {
  background-color: #0a121f;
  color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.nav-container {
  overflow-y: scroll;
  max-height: 75vh;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.nav-container::-webkit-scrollbar {
  display: none;
}

.product-img {
  height: 500px;
  max-width: 100%;
  width: 100%;
  border-radius: 10px 10px 0px 0px;
  object-fit: cover;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.react-datetime-picker__wrapper {
  border: 0px !important;
}

.panel-mobile-menu {
  display: none;
}

.video-form-thumbnail {
  height: 200px;
  object-fit: cover;
}

.vh-100 {
  height: 100vh;
}

.capitalize {
  text-transform: capitalize;
}

.card-type {
  max-width: 50px;
}

#fileInput {
  visibility: hidden;
}

.image-input-container {
  background-color: none;
  width: 200px;
  height: 50px;
  border-radius: 10px;
  margin-bottom: 12px;
}

.bmOFCG {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.hidden {
  display: none;
}

.schedule-class {
  border-radius: 5px;
  text-align: center;
  max-width: 200px;
  width: 100%;
  padding: 12px;
}

.schedule-class:hover {
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-classes-container {
  border: 1px solid rgba(0, 0, 0, 0.1);
  min-width: 100%;
  max-width: 300%;
  padding: 12px;
  float: left;
}

.class-container {
  height: 50px;
  overflow-x: hidden;
}

.text-auto {
  text-transform: none;
}

.vigencia {
  font-size: 18px;
}

.package-card {
  height: 300px;
}

.schedule-day {
  width: calc(100% / 7);
}

.offcanvas {
  visibility: visible !important;
  z-index: 15 !important;
}

.offcanvas-header {
  margin-top: 16px;
  margin-bottom: 32px;
}

.offcanvas-end {
  width: 50vw;
}

.aside-offcanvas {
  width: 400px !important;
  z-index: 2000 !important;
}

.aside-offcanvas 
.offcanvas-header {
  margin: unset;
}

#note-input {
  background-color: white !important;
}

#note-input:focus {
  border: 1px solid #ced4da !important;
  box-shadow: none;
}

.card {
  padding: 24px;
  border-radius: 0px;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
  transition: all 0.25s;
}

.card:hover {
  cursor: pointer;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.no-scale:hover {
  transform: none !important;
}

.payment-method {
  max-width: 75px;
}

.color-legend {
  height: 25px;
  width: 25px;
  border-radius: 5px;
}

.user-card {
  position: absolute;
  width: 290px;
  right: 12px;
  transition: all 0.2s;
  position: absolute !important;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
}

.sale-ribbon {
  position: absolute;
  right: 0;
  top: 20px;
}

.strike-through {
  text-decoration: line-through;
}

.transition {
  transition: all 1s;
}

.video-iframe {
  width: 100%;
  height: 75vh;
}

.btn-round {
  border-radius: 80px;
  width: 40px;
  height: 40px;
}

.btn-primary {
  text-transform: uppercase;
  font-weight: 800;
  color: #fff;
}

.btn-secondary {
  background-color: #000;
  text-transform: uppercase;
}

.btn-primary:focus {
  background-color: #000;
  box-shadow: none;
  box-shadow: none;
}

.btn-primary:hover {
  background-color: #000;
  border-color: #000;
}

.btn-primary:active {
  background-color: #000;
  box-shadow: none;
}

.btn-primary:active {
  background-color: #000;
  box-shadow: none;
}

.about-vibe {
  min-height: 500px;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

html {
  width: 100%;
  max-width: 100%;
}

.menu-category {
  font-size: 1rem;
  width: 100%;
}

.collapse-nav-item {
  color: #fff;
  display: block;
  width: 100%;
  text-decoration: none !important;
  letter-spacing: 0.5px;
  font-size: 1.2rem;
  padding-top: 12px;
  padding-bottom: 12px;
}

.collapse-nav-item {
  padding-left: 32px;
}

.collapse-nav-item:hover {
  color: #ababab;
}

.collapsible {
  transition: all 0.1s;
  overflow: hidden;
}

.closed {
  height: 0px;
}

body {
  width: 100%;
  max-width: 100%;
}

.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.side-menu-list {
  display: block;
  list-style-type: none;
  padding: 0px;
}

.collapse-menu {
  padding-left: 0px;
  list-style-type: none;
}

.collapse-menu .nav-item {
  margin: 0px;
  color: #000;
}

.nav-item {
  padding: 8px 2px;
  transition: all 0.25s;
  margin: 0px;
  color: #fff;
  border-bottom: 3px solid transparent;
}

.footer {
  z-index: 0;
}

.nav-link {
  width: 100%;
  display: block;
}

.navbar-cta {
  transition: all 0.25s;
  margin-top: 12px;
}

.program-video-card {
  cursor: pointer;
  min-height: 250px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.panel-home {
  max-width: 500px;
}

.navbar-cta:hover {
  transform: scale(1.05);
}

.navbar-brand {
  padding-top: 0px;
}

.nav-link {
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
}

.text-left {
  text-align: left !important;
}

.sidebar {
  position: fixed;
  width: 160px;
  height: 100vh;
  max-width: 160px;
  padding: 0px;
}

nav .side-menu {
  padding-right: 0 !important;
}

.pointer {
  cursor: pointer;
}

.side-menu {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  left: 0px;
  width: 160px;
  min-width: 160px;
  background-color: #191919;
  color: #fff;
  padding-top: 32px;
  padding-bottom: 64px;
  padding-right: 0px !important;
}

.main-panel {
  background: #fff;
  padding: 12px;
  margin-left: 160px;
  min-height: 100vh;
}

.logo-footer {
  max-width: 250px;
}

.h-80 {
  height: 85vh;
  overflow-y: scroll;
}

.content {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  padding-bottom: 2rem;
  height: 100%;
}

.hover-overlay {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 10px;
  cursor: pointer;
  transition: all 1s;
  padding: 16px;
}

.codex-editor {
  border: 1px solid var(--gray) !important;
  box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important;
}

.codex-editor__redactor {
  min-height: 300px !important;
  padding-bottom: 10px !important;
}

.sortable-list {
  -webkit-user-select: none;
          user-select: none;
}

.sortable-item {
  border: solid 1px var(--light);
  background-color: white;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: grab;
}

.profile-image {
  max-height: 250px;
  margin: auto;
  object-fit: contain;
  padding: 12px;
}

.profile-thumbnail {
  border-radius: 150px;
  object-fit: cover;
  height: 50px;
  width: 50px;
  
}

.logo-thumbnail {
  width: 75px;
}

.thumbnail {
  width: 75px;
  height: 75px;
}

.user-icon {
  padding: 20px 22px;
}

.large {
  font-size: 1.2em;
}

.round {
  border-radius: 1000px;
}

.hover-light:hover {
  background-color: #f8f9fa;
}

.video-thumbnail {
  height: 200px;
  object-fit: cover;
  width: 100%;
  max-width: 100%;
}

.card-video {
  height: 330px;
  margin-bottom: 32px;
  transition: all 1s;
  padding-bottom: 16px;
}

.card-video p {
  font-size: 14px;
}

.card-video h3 {
  font-size: 16px;
}

.panel-title {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin-bottom: 32px;
  font-size: 24px;
}

.filter-card {
  padding: 16px;
  margin-bottom: 24px;
}

.bold {
  font-weight: 700;
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.latest-card {
  height: 400px;
  margin-bottom: 32px;
  cursor: pointer;
  overflow: hidden;
  border-radius: 10px;
  border-radius: 0px;
}

.latest-card-thumbnail {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-name {
  font-size: 20px;
}

.dropdown-menu {
  margin-left: -45px;
}

.user {
  border-radius: 200px;
  color: #fff;
  text-align: center;
  align-items: center;
  display: flex;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.menu-logo {
  max-width: 75px;
}

.side-nav {
  display: block;
  margin-top: 32px;
  list-style-type: none;
  padding-left: 0px;
}

.side-nav li {
  margin-bottom: 16px;
}

.logout-container {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 16px;
  width: 75%;
}

.home-video {
  height: 70vh;
  padding-right: 0px;
  padding-left: 0px;
  z-index: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#video {
  height: 70vh;
}

.logo-navbar {
  max-width: 50px;
}

.vh-100 {
  height: 100vh;
}

#login-card {
  max-width: 500px !important;
  margin: auto;
  display: block;
}

.login-image {
  object-fit: cover;
  object-position: bottom;
}

.no-decoration {
  text-decoration: none !important;
}

.no-decoration:hover {
  text-decoration: none !important;
}

.class-type {
  height: 70vh;
  border-radius: 10px;
  transition: all 0.5s;
}

.select-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
  opacity: 0.5;
  border-radius: 10px;
}

.type-disabled {
  opacity: 0.5;
}

.class-card {
  background-color: #000;
  z-index: 1;
  border-radius: 10px;
}

.class-type:hover {
  transition: all 0.25s;
  transform: scale(1.01);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.class-card-wrapper:hover {
  transform: scale(1.01);
  transition: all 0.5s;
}

.select-title {
  color: #fff;
  z-index: 2;
  text-transform: uppercase;
}

.br-0 {
  border-radius: 0px !important;
}

.drawer-menu {
  z-index: 10;
  transition: all 0.25s;
  height: calc(100vh - 72px);
  max-height: calc(100vh - 72px);
  overflow-y: auto;
}

.mobile {
  border-radius: 10px;
}

.show-tablet {
  display: none;
}

.landing-logo {
  width: 350px;
}

.landing-container {
  z-index: 1;
}

.landing-row {
  height: 500px;
  position: absolute;
  top: 20vh;
  width: 100%;
}

.landing-button {
  transition: all 0.25s;
}

.landing-button:hover {
  transform: scale(1.1);
}

.bold {
  font-weight: 800;
}

.belt-text {
  z-index: 1;
}

.black-belt {
  height: 250px;
  position: absolute;
  width: 100vw;
  left: 0;
  top: 30%;
  z-index: 0;
}
.z-index-1 {
  z-index: 1;
}

.mw-500 {
  max-width: 500px;
}

.mw-800 {
  max-width: 800px;
}

.mw-1400 {
  max-width: 1320px;
}

.h-auto {
  height: auto;
}

.landing-class-type {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: cover;
  transition: 0.5s ease;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  z-index: 1;
  filter: brightness(60%);
}

.landing-class-type:after {
  background-color: #000;
  width: 100%;
  height: 100%;
}

.black-overlay {
  background-color: #000;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  opacity: 0.4;
  z-index: 0;
}

.class-type-description {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 75%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.checkout-logo {
  max-width: 100px;
}

.class-type-container {
  height: 400px;
  width: 100% !important;
  overflow: hidden;
}

.br-10 {
  border-radius: 10px;
}

.landing-class-type-container {
  position: relative;
  overflow: hidden;
  height: 400px;
}
.landing-class-type-container:hover .landing-class-type {
  filter: brightness(20%);
}

.landing-class-type-container:hover .landing-class-type:after {
  opacity: 0.4;
}

.landing-class-type-container:hover .class-type-description {
  opacity: 1;
  color: #fff;
}
.landing-class-type-container:hover .class-type-logo {
  opacity: 0;
}

.class-type-logo {
  position: absolute;
  top: 200px;
  width: 50%;
  height: 50px;
  object-fit: contain;
  left: 25%;
  text-align: center;
}

.show-mobile {
  display: none;
}

.main-cta-cont {
  padding-top: 20vh;
  z-index: 10;
}

.main-cta-cont h1 {
  text-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.main-cta-cont p {
  font-size: 24px;
  text-shadow: 0px 0.125rem 0.25rem rgba(0, 0, 0, 0.5);
}

.overlay {
  background-color: #000;
  position: absolute;
  left: 0;
  width: 100%;
  opacity: 0.75;
  z-index: 1;
}

.loading-text {
  z-index: 10;
}

.loading-logo {
  max-width: 200px;
}
.bg-vertical {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
}



@media(min-width: 681px) {
  .customer-nav {
    justify-content: start;
  }

  .customer-nav-item .nav-link {
    width: -webkit-max-content;
    width: max-content;
  }
}

@media(max-width: 680px) {
  .customer-data__container {
    margin-bottom: 20px;
  }

  .customer-nav {
    justify-content: space-between;
  }

  .customer-nav-item .nav-link {
    width: 138px;
    text-align: center;
  }
}

@media(max-width: 372px) {
  .customer-nav {
    justify-content: center;
  }
}


.customer-nav-item {
  border: unset !important;
}



.customer-nav-item:hover {
  background-color: unset !important;
  border: unset !important;
}


#loading {
  height: 100vh;
  z-index: 0;
  position: absolute;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  left: 0;
}

#main-cta {
  max-width: 300px;
}

@media (min-width: 1000px) {
  .hide-desktop {
    display: none;
  }
}

@media (max-width: 1000px) {
  .text-left-tablet {
    text-align: left !important;
  }
  .hide-tablet {
    display: none;
  }
  .show-tablet {
    display: inherit;
  }
}

@media (max-width: 767px) {
  .offcanvas-end {
    width: 90vw;
  }
  .main-panel {
    height: calc(100vh - 72px);
    min-height: calc(100vh - 72px);
  }
  .schedule-day {
    width: 100%;
  }
  .profile-image {
    max-height: 150px;
  }
  .panel-mobile-menu {
    display: block;
  }
  .show-mobile {
    display: inline;
  }
  .hide-mobile {
    display: none;
  }
  .side-menu {
    display: none;
  }
  .main-panel {
    padding-left: 12px;
    padding-right: 12px;
    margin-left: 0px;
  }
  .text-funny {
    font-size: 32px;
  }
  .user-card {
    display: none;
  }
  .nav-item {
    height: auto;
    padding-left: 8px;
    padding-right: 8px;
  }
  .nav-link {
    margin-top: 0px !important;
    text-transform: capitalize !important;
  }
  .mobile {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .schedule-container {
    width: 100%;
  }
  .h-80 {
    height: auto;
  }
  .navbar-container {
    padding-right: 24px;
    padding-top: 24px;
  }
  .mobile-center  {
    text-align: center !important;
  }
  #main-cta {
    position: relative;
    top: auto;
    left: auto;
  }
  #video {
    margin-top: -32px;
  }
}

:root {
    --dark: #000;
    --light: rgb(239,238,234);
    --primary: rgb(157,51,85);
    --primary-light: rgb(244,184,214);
    --primary-light-transparency: rgb(244, 184, 214, 0.5);
    --secondary: rgb(153,180,210);
    --secondary-light: rgb(211, 223, 237);
    --gray: rgb(198,198,198);
    --dark-gray: rgb(102,100,94);
    --accent: rgb(109,134,180);
    --warning: rgb(237,184,128);
    --accent-light: rgb(205,238,249);
    --danger: rgb(281,83,72);
    --success: rgb(207,222,134);
    --accent-transparency: rgba(117, 138, 187, 0.75);
    --light-blue: rgba(208,224,239,0.5);
    --dark-gray: #5C5954;
    --blue: #92B5D5;
    --menta: rgb(186,204,206);
}

body {
    color: #5C5954;
}

/* Nav */

.dropdown-toggle:active,
.dropdown-toggle:hover {
    background-color: var(--primary-light);
}

.nav-item:hover {
    background-color: var(--primary-light);
    border-bottom: 3px solid var(--primary);
    color: var(--primary);
}

.nav-item-active {
    background-color: var(--primary-light);
    border-bottom: 3px solid var(--primary);
    color: var(--primary);
}

/* Background */

.bg-light {
    background-color: var(--light) !important;
}

.bg-black {
    background-color: #000;
}

.bg-primary {
    background-color: var(--primary) !important;
}

.bg-secondary {
    background-color: var(--secondary) !important;
}

.bg-gray {
    background-color: var(--gray) !important;
}

.bg-dark-gray {
    background-color: var(--dark-gray) !important;  
}

.bg-opacity {
    background-color: var(--dark-transparency) !important;
}

.bg-dark {
    background-color: var(--dark) !important;
}

.bg-special {
    background-color: #C9E881 !important;
}

.bg-accent {
    background-color: var(--accent) !important;
    color: white !important;
}

.bg-accent-secondary {
    background-color: var(--accent-secondary);
}

.bg-primary-light {
    background-color: var(--primary-light);
}

.bg-secondary-light {
    background-color: var(--secondary-light);
}

.bg-danger {
    background-color: var(--danger) !important;
    color: white !important;
}

.bg-success {
    background-color: var(--success) !important;
}

.bg-gradient {
    background: linear-gradient(60deg, rgba(157,51,85,1) 0%, rgba(109,134,180,1) 52%, rgba(211,223,237,1) 100%) !important; 
}

/* Text */

.text-dark {
    color: var(--dark-gray) !important;
}

.text-accent {
    color: var(--accent);
}

.text-blue {
    color: var(--blue);
}

.text-primary {
    color: var(--primary) !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

.text-black {
    color: black;
}

/* Forms */

.form-control {
    background-color: var(--light) !important;
}

/* Buttons */

.btn-online {
    background-color: var(--primary-light);
    border-color: transparent;
    color: var(--primary);
    border-width: 0px;
}

.btn-onsite {
    background-color: var(--secondary-light);
    border-color: transparent;
    color: var(--secondary);
    border-width: 0px;
    
}

.btn-pink {
    background-color: var(--primary-light) !important;
    border: 2px solid var(--primary-light) !important;
    color: white;
}

.btn-warning {
    background-color: var(--warning) !important;
    border: 1px solid var(--warning) !important;
}

.btn-success {
    background-color: var(--success) !important;
    color: black;
}

.btn-outline-primary {
    border: 2px solid var(--primary) !important;
    color: var(--primary) !important;
    text-transform: uppercase;
    font-weight: 700;
}

.btn-outline-primary:hover {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    color: white !important;
}

.btn-primary {
    background-color: var(--primary) !important;
    border: 2px solid var(--primary) !important;    
    color: white !important;
    text-transform: uppercase;
    font-weight: 800;
}

.btn-danger {
    background-color: var(--danger) !important;
}

.btn-sucess {
    background-color: var(--success) !important;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:hover,
.btn-primary:active {
    background-color: var(--primary-light) !important;
    border: 2px solid var(--primary) !important;    
    color: var(--primary) !important;
    text-transform: uppercase;
    font-weight: 800;
}

.btn-secondary {
    border: 2px solid var(--blue) !important;
    background-color: var(--blue) !important;
    color: white;       
}

.btn-secondary:hover,
.btn-secondary:focus,
.btn-secondary:hover,
.btn-secondary:active {
    background-color: var(--accent);
    border: 2px solid white !important;
    color: white;
    text-transform: uppercase;
    font-weight: 800;
}

.btn-whatsapp {
    background-color: #198754 !important;
    color: white !important;
}

/* Accordion */

.accordion-button:not(.collapsed) {
    color: var(--primary) !important;
    background-color: var(--primary-light) !important;
    
}

.accordion-button:focus {
    box-shadow: 0 0 0 0.25rem var(--primary-light-transparency) !important;
}

/* Misc */

.reply {
    border-left: 5px solid var(--primary);
}

.reply img {
    max-height: 100px;
    object-fit: contain;
}

.name-row:focus {
    background-color: var(--light);
}

.black-belt {
    background-color: var(--gray);
}

/* Landing */

.program-meta, .class-type-meta {
    background-color: var(--light-blue);
    color: white;
}

.nutrition-tip-image {
    background-color: var(--light-blue);
}

.program-card:hover .program-meta {
    background-color: var(--primary) !important;
}

.plan-meta {
    background-color: var(--primary);
}

.plan-meta-button {
    background-color: var(--menta) !important;
    border-radius: 150px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 2px solid white;
    width: 60px;
    height: 60px;
    font-size: 24px;
    position: absolute;
    top: -25px;
    right: 25px;
}

.outline-coach-image {
    border: 1px solid var(--primary) !important;
}

.bg-coach-image {
    background-color: var(--primary) !important;
}

.outline-estreno-image {
    border: 1px solid var(--accent-light) !important;
}

.bg-estreno-image {
    background-color: var(--accent-light) !important;
}


#post-input {
    position: absolute;
    bottom: -50px;
    width: 100%;
}

.post-actions {
    position: absolute;
    width: 150px;
    right: 75px;
    bottom: 15px;
    z-index: 10;
}

#posts {
    height: 100vh;
}

#posts-content {
    height: 85vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

.emoji-picker-wrapper {
    position: relative;
}

.emoji-picker-wrapper button {
    position: absolute;
    top: -575px;
    right: -5px;
}

aside.EmojiPickerReact.epr-main {
    position: absolute !important;
    top: -535px;
    right: -5px;
}

#greeting {
    height: 80vh;
}

@media (max-width: 767px) {
    #posts {
        height: calc(100vh - 70px);
    }
    #posts-content {
        height: 75vh;
        overflow-y: scroll;
        overflow-x: hidden;
    }
    #post-input {
        bottom: -100px;
    }
}
@font-face {
    font-family: "Francois One";
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/FrancoisOne-Regular.woff") format("woff")
}

@font-face {
    font-family: "Futura";
    font-weight: 400;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-light.woff") format("woff")
}

@font-face {
    font-family: "Futura Condensed";
    font-weight: 400;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-light.woff") format("woff")
}

@font-face {
    font-family: "Futura";
    font-weight: 800;
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura.woff") format("woff")
}

@font-face {
    font-family: "Futura Condensed Extra Bold";
    src: url("https://tbmcerts.s3.us-west-1.amazonaws.com/futura-condensed-extra-bold.woff") format("woff")
}


body, span, li, div, p, .font-body, .accordion-button, .nav-link, .font-poppins {
    font-family: "Poppins", sans-serif !important;
}

.h2.font-poppins, .h3.font-poppins {
    font-family: "Poppins", sans-serif !important;
    text-transform: none;
    font-weight: 300 !important;
}

p {
    font-size: 1.2rem;
}

p.medium {
    font-size: 1rem;
}

.text-sentence {
    font-style: normal;
    text-transform: initial;
}

.font-open-sans {
    font-family: "Open Sans", sans-serif !important;
}

.font-style-italic {
    font-style: italic !important;
}

.futura {
    font-family: "Futura", sans-serif !important;
}

.futura-bold {
    font-family: "Futura Condensed Extra Bold", sans-serif !important;
}

.francois {
    font-family: "Francois One", sans-serif !important;
}

h1, h2, .font-header {
    font-weight: bold;
    line-height: 1.8em;
    font-family: "Futura Condensed Extra Bold", sans-serif !important;
}

h3, h4, h5, h6, .h3, .h4, .h5, .h6, .font-header-secondary {
    text-transform: uppercase;
    font-family: "Futura Condensed Extra Bold", sans-serif !important;
}

.unstyled {
    font-style: none !important;
}

h1.display-1 {
    font-size: calc(4rem + 4.5vw);
}
#video {
    margin-top: 60px;
}

.home-video {
    padding-right: 0px;
    padding-left: 0px;
    z-index: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.plan-card {
    height: 600px;
}

.plan-meta {
    height: 200px;
}

.plan-card .btn-pink {
    position: absolute;
    top: 0px;
    right: 10px;
}

.plan-image {
    top: 0px;
    left: 0px;
    height: 450px;
    object-fit: cover;
    position: absolute;
    border-radius: 15px 15px 0px 0px;
}

.class-type-card {
    height: 300px;
}

.class-type-card-lg {
    height: 650px;
}

.class-type-videos {
    position: absolute;
    right: 10px;
    top: 10px;
}

.class-type-meta {
    height: 75px;
}

.class-type-row * {
    transition: all 0.25s;
}

.class-type-wrapper, .coaches-wrapper {
    max-width: 100vw;
    overflow: hidden;
}

.estrenos-wrapper {
    height: 250px;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
}

.outline-coach-image {
    border-radius: 60px;
    position: absolute;
    height: 335px;
    width: 250px;
    z-index: 0;
    left: 35px;
    top: 30px;
}

.outline-estreno-image {
    border-radius: 20px;
    position: absolute;
    height: 300px;
    width: 200px;
    z-index: 0;
    left: -5px;
    top: 0px;
}

.bg-coach-image {
    border-radius: 60px;
    position: absolute;
    height: 325px;
    width: 250px;
    z-index: 0;
    left: 70px;
    top: 50px;
}

.bg-estreno-image {
    border-radius: 30px;
    position: absolute;
    height: 300px;
    width: 200px;
    right: 25px;
    top: 25px;
}

.estreno-image {
    height: 100%;
    object-fit: cover;
}

.estreno-card {    
    width: 300px;
    display: flex;
    flex-direction: column;
}

.estreno-card .date-overlay {
    position: absolute;
    top: 50px;
    left: 15px;
    width: 90%;
}

.estreno-card img {
    height: 150px; 
    object-fit: cover;
}

.video-estreno-image {
    border-radius: 20px;
    height: 200px;
    width: 400px;
}

.home-coach-image {
    z-index: 1;
    margin: auto;
    display: block;    
    object-fit: cover;
}

.estrenos-row {
    transition: all 0.25s;
}

.coaches-row {
    max-height: auto;
}

.class-type-row, .coaches-row {
    transition: all 0.25s;
    overflow-x: visible;
    overflow-y: hidden;
    max-width: 300%;
    max-height: 700px;
    width: 300%;
}

.class-type-column, .coach-column {
    height: 780px;
    overflow-y: hidden;
    overflow-x: hidden;
    transition: all 0.5s;
    width: 25vw;
}

.class-type-meta, .plan-meta, .program-meta {
    border-radius: 0px 0px 15px 15px;
    justify-content: center;
    flex-direction: column;
    transition: all 0.25s;
    align-items: center;
    position: absolute;    
    display: flex;
    width: 100%;
    bottom: 0px;
    left: 0px;
}

.panel-card {
    height: 500px !important;
    transition: all 0.25s;
}

.program-card {
    height: 500px;
    transition: all 0.25s;
}

.nutrition-tip-image {
    border-radius: 0px 0px 25px 25px;
    position: absolute;
    bottom: 0px;
}

.program-meta { 
    height: 100px;
}

.program-meta .program-title {
    margin-bottom: 0px;
    font-size: 22px;
}

.program-description {
    display: none;
}

.program-card:hover .program-meta {
    height: 200px;
}

.program-card:hover .program-description {
    display: block;
}
.program-card:hover .program-title {
    margin-bottom: 12px;
}

#single-estreno { 
    height: 380px;
}

#video .tagline {
    font-size: 34px;
}

#about img {
    max-height: 350px;
    object-fit: cover;
    
}

#about #center-image {
    height: 600px;
}

#video .card {
    background-color: rgba(10,10,10,0.25);
    border-radius: 15px;
    color: white;
}

#video,
.home-video {
    height: 70vh;
}


/* To Do: Centrar en todas las pantallas */
#video #buttons {
    position: absolute;
    width: 75%;
    left: 0;
    bottom: -32px;
    margin-left: 12.5%;
}

#quote #buttons {
    max-width: 1000px;
    margin: auto;
}

@media (max-width: 767px) {
    .display-1 {
        font-size: 2.4rem !important;
    }
    .tagline { 
        font-size: 1.8rem !important;
    }
    #video #buttons {
        position: relative;
        margin-left: 0px;
        margin-top: 0px;        
        width: 100%;         
    }
    .coach-column {            
        overflow-y: hidden;
        overflow-x: hidden;
        width: 90vw;
    }
    .class-type-row {
        height: 475px;
    }
    .class-type-column {
        width: 75vw;
        height: 500px;
    }
    .class-type-card-lg {
        height: 400px;
    }
    .carousel-control-prev.wide {
        margin-left: -50px;
    }
    .carousel-control-next.wide {
        margin-right: -50px;
    }
    .carousel-control-prev.dark {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    .carousel-control-next.dark {
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    .plan-image {
        height: 300px;        
    }
    .plan-card {
        height: 450px;
    }
    .plan-meta {
        padding: 16px;
    }
    .navbar-cta {
        margin-top: 0px;
    }
    nav .collapse.show {
        margin-bottom: 16px;
    }
    .estreno-image {
        height: auto;
        margin-top: 24px;
        object-fit: cover;
    }
    .program-video-card {
        min-height: auto;
    }
    #single-estreno .h-100 {
        height: auto !important;
    }
    #single-estreno {
        height: 500px;
    }
}
.schedule-col {
    width: calc(100% / 8);
}

.schedule-day {
    width: calc(100% / 7);
}

.schedule-class {
    border-radius: 5px;
    text-align: center;
    padding: 12px;
}

.schedule-class:hover {
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.15) !important;
}

.schedule-classes-container {
    border: 1px solid rgba(0, 0, 0, 0.1);
    min-width: 100%;
    max-width: 300%;
    padding: 12px;
    float: left;
}

.schedule-mobile-container {
    overflow-x: scroll;
}

.schedule-mobile-wrapper {
    width: 300%;
}

#schedule-mobile-wrapper {
    display: none;
}

@media(max-width: 767px) {
    .schedule-col {
        width: calc(100% / 7);
    }

    .schedule-day {
        width: 100%;
    }

    .schedule-container {
        width: 100%;
    }

    #schedule-mobile-wrapper {
        display: inherit;
    }
}
